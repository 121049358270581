/**
 * Created by osirvent on 17/03/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentsByTypeConfiguration', {
        templateUrl: './components/doc/annexa-documents-by-type-configuration/annexa-documents-by-type-configuration.html',
        controller: ['Language', 'RestService', 'AdminFactory', 'GlobalDataFactory', function(Language, RestService, AdminFactory, GlobalDataFactory) {
            var vm = this;

            var normalColumns = [
                { id: 'docStatus', language1: 'global.literals.state', language2: '', language3: '', type: 'NORMAL', table: true, filter: false, folder: false },
                { id: 'favorite', language1: 'global.literals.favorite', language2: '', language3: '', type: 'NORMAL', table: true, filter: false, folder: false },
                { id: 'code', language1: 'global.literals.code', language2: '', language3: '', type: 'NORMAL', table: true, filter: true, folder: true },
                { id: 'name', language1: 'global.literals.title', language2: '', language3: '', type: 'NORMAL', table: true, filter: true, folder: false },
                { id: 'dossierTransactionDocuments', language1: 'global.literals.trams', language2: '', language3: '', type: 'NORMAL', table: true, filter: false, folder: false },
                { id: 'createUser', language1: 'global.literals.user', language2: '', language3: '', table: true, type: 'NORMAL', filter: true, folder: true },
                { id: 'modifyDate', language1: 'global.literals.modifyDate', language2: '', language3: '', type: 'NORMAL',table: true, filter: false, folder: true },
                { id: 'modifyDdate', language1: 'global.literals.modifyDate', language2: '', language3: '', type: 'NORMAL',table: false, filter: true, folder: false },
                { id: 'template', language1: 'global.literals.template', language2: '', language3: '', type: 'NORMAL', table: false, filter: true, folder: false },
                { id: 'section', language1: 'global.literals.section', language2: '', language3: '', type: 'NORMAL', table: false, filter: true, folder: false },
                { id: 'origin', language1: 'global.literals.module', language2: '', language3: '', type: 'NORMAL', table: false, filter: true, folder: false },
                { id: 'createdDate', language1: 'global.literals.creation_date', language2: '', language3: '', type: 'NORMAL',table: true, filter: false, folder: true },
                { id: 'digitalized', language1: 'global.documents.scanned', language2: '', language3: '', type: 'NORMAL',table: false, filter: true, folder: false },                
            ]

            _.forEach(normalColumns, function (column, index) {
                Language.getAllTranslations(column.language1)
                    .then(function (data) {
                        normalColumns[index].language1 = data.language1;
                        normalColumns[index].language2 = data.language2;
                        normalColumns[index].language3 = data.language3;
                });
            });

            vm.loading = true;

            vm.languageColumn = Language.getActiveColumn();

            if(!vm.model) {
                vm.model = []
            }

            var initializeTableColumnsList = function (customFields) {
                var columnsList = [];

                var list = $linq(normalColumns).where("x => x.table").toArray();

                _.forEach(list, function (column) {
                    columnsList.push(column);
                });

                _.forEach(customFields, function (customField) {
                    var custField = { id: customField.id, language1: customField.language1, language2: customField.language2, langauge3: customField.language3, type: 'CUSTOM', table: true, filter: true, folder: true };
                    columnsList.push(customField);
                });

                return columnsList;
            };

            var initializeFilterColumnsList = function (customFields) {
                var columnsList = [];

                var list = $linq(normalColumns).where("x => x.filter").toArray();

                _.forEach(list, function (column) {
                    columnsList.push(column);
                });

                _.forEach(customFields, function (customField) {
                    var custField = { id: customField.id, language1: customField.language1, language2: customField.language2, langauge3: customField.language3, type: 'CUSTOM', table: true, filter: true, folder: true };
                    columnsList.push(customField);
                });

                return columnsList;
            };

            var initializeFolderColumnsList = function(customFields) {
                var columnsList = [];

                var list = $linq(normalColumns).where("x => x.folder").toArray();

                _.forEach(list, function (column) {
                    columnsList.push(column);
                });

                _.forEach(customFields, function (customField) {
                    var custField = { id: customField.id, language1: customField.language1, language2: customField.language2, langauge3: customField.language3, type: 'CUSTOM', table: true, filter: true, folder: true };
                    columnsList.push(customField);
                });

                return columnsList;
            };

            vm.selectedDocType = undefined;
            vm.documentTypes = [];
            vm.iconTypes = [
                { id: 'AWESOME', description: 'Awesome' },
                { id: 'MATERIALICONS', description: 'Material Design' }
            ]

            var types = GlobalDataFactory.documentTypes;

            var types = $linq(types).select(function(x) {
                var customFields = $linq(x.customFields).select("x => x.customField").toArray();

                return { id: x.id, language1: x.language1, language2: x.language2, language3: x.language3, customFields: customFields }
            }).toArray();

            var configuredTypes = $linq(vm.model).select("x => x.type").toArray();
            var selectableTypes = types;

            if(configuredTypes.length != 0) {
                selectableTypes = $linq(types).except(configuredTypes, "(x,y) => x.id == y.id").toArray();
            }


            vm.documentTypes = selectableTypes;

            if(vm.model) {
                _.forEach(vm.model, function (item, index) {
                    var cType = $linq(types).singleOrDefault(undefined, "x => x.id == " + item.type.id);
                    if(cType) {
                        var custom
                        vm.model[index].tableColumnsList = initializeTableColumnsList(cType.customFields);
                        vm.model[index].filterColumnsList = initializeFilterColumnsList(cType.customFields);
                        vm.model[index].folderColumnsList = initializeFolderColumnsList(cType.customFields);
                    }
                });

            }

            vm.loading = false;

            vm.removeType = function(index) {
                var type = vm.model[index].type;

                vm.model.splice(index, 1);

                vm.documentTypes.push(type);
            };

            vm.addType = function() {
                if(vm.selectedDocType) {
                    vm.model.push({
                        type: vm.selectedDocType,
                        showList: true,
                        showFolders: true,
                        iconType: { id: 'AWESOME', description: 'Awesome' },
                        icon: '',
                        tableColumns: [],
                        filterColumns: [],
                        folderColumns: [],
                        tableColumnsList: initializeTableColumnsList(vm.selectedDocType.customFields),
                        filterColumnsList: initializeFilterColumnsList(vm.selectedDocType.customFields),
                        folderColumnsList: initializeFolderColumnsList(vm.selectedDocType.customFields)
                    });

                    var selectedDocTypeIndex = $linq(vm.documentTypes).indexOf("x => x.id == " + vm.selectedDocType.id);
                    vm.selectedDocType = undefined;
                    if(selectedDocTypeIndex != -1) {
                        vm.documentTypes.splice(selectedDocTypeIndex, 1);
                    }
                }
            }
        }],
        bindings: {
            model: '=',
            form: '='
        }
    });