/**
 * Created by osirvent on 27/03/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentViewModes', {
        templateUrl: './components/doc/annexa-document-view-modes/annexa-document-view-modes.html',
        controller:['DocumentsViewModesFactory', 'DccumentsFactory', 'globalModals', 'AnnexaFormlyFactory', '$rootScope', 'Language', '$localStorage', function (DocumentsViewModesFactory, DccumentsFactory, globalModals, AnnexaFormlyFactory, $rootScope, Language, $localStorage) {
            var vm = this;

            var setting = $rootScope.app.name + '-ActiveViewMode';

            vm.items = [];
            vm.actualPage = 0;
            vm.scrollDisabled = false;
            vm.documentViewModeType = -1;

            vm.definition.reload = function() {
                vm.itemSelected(vm.definition.selected);
            }

            vm.treeOptions = {
                nodeChildren: 'childs',
                multiSelection: false,
                allowDeselect: false,
                expandOnLabelClick: true,
                isLeaf: function(node) {
                    return false;
                }
            };

            $rootScope.$on('documentActionButtonExecuted', function(event, args) {
                switch (args.button) {
                    case 'delete':
                        var indexRemove = $linq(vm.items).indexOf("x => x.id == " + args.data);

                        if(indexRemove != -1) {
                            vm.items.splice(indexRemove, 1);
                        }
                        break;
                }
            });

            var loadItems = function(node) {
                if(node) {
                    var query = {filterCall: vm.table.filterCallFunc(), filterCallAux: vm.table.filterCallAuxFunc()};

                    if (node.parent) {
                        DocumentsViewModesFactory.getDocuments(node.id, query, vm.actualPage)
                            .then(function (data) {
                                var docs = $linq(data.content).orderBy("x => x.name", linq.caseInsensitiveComparer).toArray();

                                _.forEach(docs, function (doc) {
                                    doc.type = DccumentsFactory.getDocumentViewModeElementType(doc.fileTypeDoc);
                                    doc.documentViewModeValue = node.id;

                                    vm.items.push(doc);
                                });

                                vm.scrollDisabled = false;
                            }).catch(function (error) {
                            vm.scrollDisabled = false;
                        });
                    } else {
                        DocumentsViewModesFactory.getRootDocuments(vm.documentViewModeType, query, vm.actualPage)
                            .then(function (data) {
                                var docs = $linq(data.content).orderBy("x => x.name", linq.caseInsensitiveComparer).toArray();

                                _.forEach(docs, function (doc) {
                                    doc.type = DccumentsFactory.getDocumentViewModeElementType(doc.fileTypeDoc);
                                    doc.documentViewModeValue = node.id;

                                    vm.items.push(doc);
                                });

                                vm.scrollDisabled = false;
                            }).catch(function (error) {
                            vm.scrollDisabled = false;
                        });
                    }
                }
            };

            vm.fileMove = function(event, source, target) {
                DocumentsViewModesFactory.moveDocument(source.documentViewModeValue, target.id, source.id)
                    .then(function(data) {
                        var indexRemove = $linq(vm.items).indexOf("x => x.id == " + source.id);

                        if(indexRemove != -1) {
                            vm.items.splice(indexRemove, 1);
                        }
                    }).catch(function (error) {
                });

            }

            vm.newFolder = function () {
                var modal = globalModals.newFolder;
                modal.annexaFormly.model = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModal("modalNewFolder", modal, vm.newFolderSubmit, false);
            };

            vm.newFolderSubmit = function () {
                var self = this;

                DocumentsViewModesFactory.newContainer(vm.definition.selected.documentViewModeType.id, vm.definition.selected.id, self.annexaFormly.model.name)
                    .then(function(data) {
                        data.type = 'folder'
                        data.modifyDate = undefined;
                        vm.definition.selected.childs.push(data);
                        self.close();
                        vm.itemSelected(vm.definition.selected);
                    }).catch(function(error) {

                });
            };

            vm.scroll = function() {
                if(vm.scrollDisabled) {
                    return;
                }

                vm.scrollDisabled = true;
                vm.actualPage++;

                loadItems(vm.definition.selected)
            }

            vm.itemSelected = function(node) {
                vm.items = [];
                vm.actualPage = 0;
                vm.scrollDisabled = false

                vm.definition.selected = node;

                var folders = $linq(node.childs).orderBy("x => x.name", linq.caseInsensitiveComparer).toArray();

                _.forEach(folders, function (child) {
                    vm.items.push({
                        id: child.id,
                        name: child.name,
                        type: 'folder',
                        modifyDate: undefined
                    })
                });

                loadItems(node);
            };

            vm.itemSelectedFolder = function(item) {
                if(item && item.id && vm.definition && vm.definition.selected && vm.definition.selected.childs) {
                    var node = null;
                    _.forEach(vm.definition.selected.childs, function(value){
                        if(value && value.id == item.id){
                            node = value;
                        }
                    });
                    if(node) {
                        vm.itemSelected(node);
                    }
                }
            };

            vm.printCustomColumn = function (item, column) {
                var formatDate = function(stringDate) {
                    var date = new Date(stringDate);

                    var dd = date.getDate();
                    var mm = date.getMonth()+1;

                    var yyyy = date.getFullYear();

                    if(dd<10){
                        dd='0'+dd;
                    }

                    if(mm<10){
                        mm='0'+mm;
                    }

                    return dd+'/'+mm+'/'+yyyy;
                }

                var customColumn = column.customColumn;

                var content = '';

                var custValue = $linq(item.customFields).singleOrDefault(undefined, "x => x.customField.id == " + customColumn.id);

                if(custValue) {
                    var value = angular.fromJson(custValue.value);

                    if(value && value.value) {
                        if(customColumn.frontendType == 'INPUT' || customColumn.frontendType == 'TEXTAREA') {
                            if(customColumn.backendType != 'DATETIME') {
                                content += value.value;
                            } else {
                                content += formatDate(value.value);
                            }
                        } else if(customColumn.frontendType == 'SELECT' || customColumn.frontendType == 'MULTIPLESELECT' || customColumn.frontendType == 'CHECKBOX' || customColumn.frontendType == 'RADIO') {
                            var listValue = $linq(customColumn.listValues).singleOrDefault(undefined, "x => x.value == " + value.value);

                            if(listValue) {
                                content += listValue[Language.getActiveColumn()];
                            }
                        }
                    }
                }

                return content;
            }

            var getSelected = function(data, selected) {
                var ret = undefined;

                if(data.id == selected.id) {
                    return data;
                } else {
                    _.forEach(data.childs, function (child) {
                        if(!ret) {
                            ret = getSelected(child, selected);
                        }
                    });
                }

                return ret;
            }

            var getExpanded = function(nodeSelected, expanded) {
                if(!expanded) {
                    expanded = [];
                }

                if(nodeSelected.parent == null) {
                    expanded.unshift(nodeSelected);
                    return expanded;
                } else {
                    expanded.unshift(nodeSelected);
                    expanded = getExpanded(nodeSelected.parent, expanded);
                }

                return expanded;
            }

            var changeViewMode = function(id, selected) {
                if (id != -1) {
                    vm.definition.showDefault = false;
                    vm.documentViewModeType = id;

                    DocumentsViewModesFactory.getDocumentViewModeRootValue(id)
                        .then(function(data) {
                            data.root = true;
                            vm.definition.vmRoot = [ data ];
                            if(selected) {
                                vm.definition.selected = getSelected(data, selected);
                                if(vm.definition.selected) {
                                    vm.definition.expanded = getExpanded(vm.definition.selected);
                                } else {
                                    vm.definition.selected = data;
                                    vm.definition.expanded = [ data ];
                                }
                            } else {
                                vm.definition.selected = data;
                                vm.definition.expanded = [ data ];
                            }
                            vm.itemSelected(vm.definition.selected);

                            $rootScope.$broadcast('documentsViewModePaint');
                        })
                }
            }

            $rootScope.$on('documentsViewModeChange', function(event, args) {
                var selected = undefined;

                if(args.selected) {
                    selected = args.selected;
                }

                changeViewMode(args.id, selected);

                $localStorage[setting] = args.id;
            });

            $rootScope.$on('tableFilterSubmit', function(event, args) {
                vm.itemSelected(vm.definition.selected);
            });

            changeViewMode(vm.definition.active);
        }],
        bindings: {
            definition: '=',
            table: '='
        }
    });