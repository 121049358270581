/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentAttachments', {
        templateUrl: './components/doc/annexa-document-attachments/annexa-document-attachments.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: ['$rootScope', 'DccumentsFactory', 'globalModals', 'AnnexaFormlyFactory', 'CommonService', function ($rootScope, DccumentsFactory, globalModals, AnnexaFormlyFactory, CommonService) {
            var vm = this;

            this.$onInit = function() {
                vm.dissabled = !_.contains(['NEW', 'NEW_AND_ATTACHMENT'], vm.documentComponent.document.docStatus) ? false : true;
                vm.dissabledClass = vm.dissabled ? '' : 'linkDisabled';
                vm.attachments = vm.documentComponent.document.attachments && vm.documentComponent.document.attachments.length > 0 ? vm.documentComponent.document.attachments : [];
            }
            vm.calculateSizeFile = function(size){
                if(size){
                    return CommonService.getFileSizeDescription(size);
                }
                return "";
            }

            vm.newAttachment = function () {
                var addAttachment = function () {
                    if (this.annexaFormly.form.$valid) {
                        $rootScope.loading(true);
                        var self = this;
                        var data = this.annexaFormly.model.modal_body;

                        DccumentsFactory.addAttachments(data.file, vm.documentComponent.document.id)
                            .then(function(data) {
                            	if(!vm.attactments){
                            		vm.attachments = [];
                            	}
                                if(data) {
                                    _.forEach(data, function(attach){
                                    	vm.attachments.push(attach);
                                    })
                                }

                                self.close();

                                $rootScope.loading(false);
                            }).catch(function(error) {
                                $rootScope.loading(false);
                        })
                    }
                }

                var modal = angular.copy(globalModals.uploadMultiFile);
                modal.title = 'global.documents.new.attachmentNewFile';
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModal('modalAddAttachment', modal, addAttachment, false);
            }

            vm.deleteAttachment = function (attachment) {
                $rootScope.loading(true);
                DccumentsFactory.deleteAttachment(attachment.id)
                    .then(function (data) {
                        vm.attachments = data;
                        $rootScope.loading(false);
                    }).catch(function () {
                    $rootScope.loading(false);
                });
            }

            vm.viewOrDownloadDocument = function (attachment) {
                $rootScope.loading(true);

                DccumentsFactory.getTemporalDocumentURL(attachment.idDocumentManager, attachment.fileTypeDoc, attachment.fileName)
                    .then(function(data) {
                        var file = new Blob([data.data], { type: data.contentType });

                        switch(data.type) {
                            case 'VIEW':
                                window.open(data.data, "_blank");
                                break;
                            case 'DOWNLOAD':
                                if(window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(file, attachment.fileName);
                                } else {
                                    var linkElement = document.createElement('a');
                                    var fileUrl = URL.createObjectURL(file);
                                    linkElement.setAttribute('href', fileUrl);
                                    linkElement.setAttribute('download', attachment.fileName);
                                    var clickEvent = new MouseEvent("click", {
                                        "view": window,
                                        "bubbles": true,
                                        "cancelable": false
                                    });
                                    linkElement.dispatchEvent(clickEvent);
                                }
                                break;
                        }

                        $rootScope.loading(false);
                    }).catch(function(error) {
                        //Empty
                });
            }

            vm.viewOrDownloadDocument1 = function(attachemnt) {
                $rootScope.loading(true);
                DccumentsFactory.viewOrDownloadDocument(attachemnt.idDocumentManager, attachemnt.fileTypeDoc, attachemnt.fileName)
                    .then(function(data) {
                        var file = new Blob([data.data], { type: data.contentType });
                        switch(data.type) {
                            case 'VIEW':
                                $rootScope.loading(false);
                                window.open(data.data, "_blank")
                                break;
                            case 'DOWNLOAD':
                                $rootScope.loading(false);
                                if(window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(file, attachemnt.fileName);
                                } else {
                                    var linkElement = document.createElement('a');
                                    var fileUrl = URL.createObjectURL(file);
                                    linkElement.setAttribute('href', fileUrl);
                                    linkElement.setAttribute('download', attachemnt.fileName);
                                    var clickEvent = new MouseEvent("click", {
                                        "view": window,
                                        "bubbles": true,
                                        "cancelable": false
                                    });
                                    linkElement.dispatchEvent(clickEvent);
                                }
                                break;
                        }
                    }).catch(function(error) {
                        //Empty
                });
            }

        }]
    })