/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentInformationBox', {
        templateUrl: './components/doc/annexa-document-information-box/annexa-document-information-box.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: ['Language', 'DccumentsFactory', function (Language, DccumentsFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            vm.updateCustomField = function (field) {
                var model = {
                    document: { id: vm.documentComponent.document.id },
                    customField: { id: field.customField.id },
                    required: field.required,
                    viewOrder: field.viewOrder,
                    value: field.value,
                    id: field.id
                };

                DccumentsFactory.updateDocumentCustomField(model)
                    .then(function(data) {
                        //Empty
                    }).catch(function(error) {
                        //Empty
                });
            };
        }]
    })