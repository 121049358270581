/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentPublish', {
        templateUrl: './components/doc/annexa-document-publish/annexa-document-publish.html',
        controller: ['$q', '$filter', '$http', 'Language', 'RestService', '$rootScope', '$scope', function ($q, $filter, $http, Language, RestService, $rootScope, $scope) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            vm.model = {}
            vm.publishPlugins = [];

            $http({
                url: './api/plugin/publish/list'
            }).then(function(data) {
                vm.publishPlugins = [];
                _.forEach(data.data, function (value) {
                    value.pluginActive = false;
                    if(value.availableStates && value.availableStates.length > 0){
                        value.pluginActive = $linq(value.availableStates).contains(vm.document.docStatus);
                    }
                    if(value.pluginActive) {
                        value.checked = false;
                        value.selected = false;
                        $http({
                            url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id
                        }).then(function(data) {
                            if(data.data && data.data.length == 2){
                                value.checked = data.data[0];
                                value.unpublish = data.data[1];
                                value.selected = false;
                                if(value.unpublish){
                                    value.unpublishFunction = function(){
                                        $http({
                                            url: './api/plugin/publish/target/'+value.target+'/document/'+vm.document.id+'/unpublish'
                                        }).then(function(data) {
                                            value.checked = false;
                                            value.selected = false;
                                            $rootScope.$broadcast('documentActionButtonExecuted', {button:'unpublishDocument', documentId:vm.document.id});
                                        }).catch(function(error){
                                            var a = 0;
                                        });
                                    }
                                }
                            }else{
                                value.checked = false;
                                value.selected = false;
                                value.unpublish = false;
                            }
                        }).catch(function(error){
                            value.checked = false;
                            value.selected = false;
                            value.unpublish = false;
                        });
                        value.parameters = angular.fromJson(value.parameters);
                        vm.model[value.target] = undefined;
                        _.forEach(value.parameters, function (val, index) {
                            if (val.frontendType != 'INPUT' && val.frontendType != 'TEXTAREA') {
                                value.parameters[index].optionsUrl = './api/plugin/publish/list_values/' + value.target + '/' + val.id;
                                value.parameters[index].optionOnSelect = function (id, $item) {
                                    var linkedParameters = $linq(value.parameters).where("x => x.linked == '" + id + "'").toArray();

                                    _.forEach(linkedParameters, function (lp) {
                                        var indexParameter = $linq(value.parameters).indexOf("x => x.id == '" + lp.id + "'");

                                        if (indexParameter != -1) {
                                            if (!vm.model[value.target]) {
                                                vm.model[value.target] = {};
                                            }
                                            vm.model[value.target][lp.id] = undefined;
                                            value.parameters[indexParameter].optionsUrl = './api/plugin/publish/list_values/' + value.target + '/' + lp.id + '/' + (($item && $item.id)?$item.id:$item);
                                        }
                                    })
                                }
                            } else {
                                value.parameters[index].optionsUrl = undefined;
                                if (!vm.model[value.target]) {
                                    vm.model[value.target] = {};
                                }
                                vm.model[value.target][val.id] = undefined;
                            }
                            value.parameters[index].showFunction = function(){
                                if(val.modules && val.modules.length > 1){
                                    var param = $linq(value.parameters).where("x => x.id == '" + val.modules[0] + "'").toArray();
                                    if(param && param.length > 0){
                                        var trobat = false;
                                        for(var i=1; i<val.modules.length; i++) {
                                            if (vm.model && vm.model[value.target] && vm.model[value.target][param[0].id] && vm.model[value.target][param[0].id] == val.modules[i]) {
                                                trobat = true;
                                            }
                                        }
                                        return trobat;
                                    }
                                    return true;
                                }else{
                                    return true;
                                }
                            }
                        })
                    }
                    vm.publishPlugins.push(value);
                })
            }).catch(function (error) {
                vm.publishPlugins = [];
            });

            vm.selectPlugin = function($event, index) {
                var checkbox = $event.target;

                vm.publishPlugins[index].selected = checkbox.checked;
            }

            $scope.$on('annexaCustomFieldComponentModelChanged', function (event, args) {
                if(args.plugin){
                    if(!vm.model[args.plugin]){
                        vm.model[args.plugin] = {};
                    }
                    vm.model[args.plugin][args.key] = args.model;
                }

            });

            var isPluginModelValid = function(target) {

            }
            this.$onInit = function () {
                vm.submitFunction = function () {
                    var self = this;
                    var havePlugins = false;
                    _.forEach(vm.publishPlugins, function (plugin) {
                        if(plugin.selected) {
                            havePlugins = true;
                            var a = vm;
                            var publishDocument = {
                                document: {id:vm.document.id},
                                target: plugin.target,
                                published: false
                            };
                            if(vm.dossierId) {
                                publishDocument.originType = 'DOSSIER';
                                publishDocument.originId = vm.dossierId;
                            } else {
                                publishDocument.originType = 'NONE';
                            }
                            var parameters = [];
                            _.forEach(plugin.parameters, function (value) {
                                var parameter = {id:value.id, value:vm.model[plugin.target][value.id]};
                                if(parameter.value && parameter.value.id){
                                    parameter.value = parameter.value.id;
                                }
                                parameters.push(parameter);
                            });
                            publishDocument.parameter = angular.toJson(parameters);
                            RestService.insert('./api/plugin/publish', publishDocument).then(function (data) {
                                self.close();
                                $rootScope.$broadcast('documentActionButtonExecuted', {button:'publishDocument', data:data.document});
                            }).catch(function () {
                                self.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
                            })
                        }
                    });
                    if(!havePlugins){
                        self.close();
                    }
                }
            }
        }],
        bindings: {
            document: '=',
            dossierId: '@?',
            submitFunction: '='
        }
    })
    .component('annexaDocumentsPublish', {
        templateUrl: './components/doc/annexa-document-publish/annexa-document-publish.html',
        controller: ['$q', '$filter', '$http', 'Language', 'RestService', '$rootScope', '$scope', function ($q, $filter, $http, Language, RestService, $rootScope, $scope) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            vm.model = {};
            vm.publishPlugins = [];
            var getAvailableDocuments = function(documents, availableStates){
                var available = 0;
                if(documents && availableStates) {
                    _.forEach(documents, function(document){
                        if($linq(availableStates).contains(document.docStatus)){
                            available++;
                        }
                    });
                }
                return available;
            };
            $http({
                url: './api/plugin/publish/list'
            }).then(function(data) {
                vm.publishPlugins = [];
                _.forEach(data.data, function (value) {
                    value.pluginActive = false;
                    value.availables = getAvailableDocuments(vm.documents, value.availableStates );
                    if(value.availables > 0){
                        value.pluginActive = true;
                        value.checked = false;
                        value.selected = false;
                        $http({
                            url: './api/plugin/publish/target/'+value.target+'/documents/',
                            method: 'POST',
                            data: JSOG.encode({"documentsId": $linq(vm.documents).select("x=>x.id").toArray()})
                        }).then(function(data) {
                            if(data.data && data.data.length == 2){
                                value.checked = data.data[0];
                                value.unpublish = data.data[1];
                                value.selected = false;
                                if(value.unpublish){
                                    value.unpublishFunction = function(){
                                        $http({
                                            url: './api/plugin/publish/target/'+value.target+'/documents/unpublish',
                                            method: 'POST',
                                            data: JSOG.encode({"documentsId": $linq(vm.documents).select("x=>x.id").toArray()})
                                        }).then(function(data) {
                                            value.checked = false;
                                            value.selected = false;
                                            $rootScope.$broadcast('documentActionButtonExecuted', {button:'unpublishDocument'});
                                        }).catch(function(error){
                                            var a = 0;
                                        });
                                    }
                                }
                            }else{
                                value.checked = false;
                                value.selected = false;
                                value.unpublish = false;
                            }
                        }).catch(function(error){
                            value.checked = false;
                            value.selected = false;
                            value.unpublish = false;
                        });
                        value.parameters = angular.fromJson(value.parameters);
                        vm.model[value.target] = undefined;
                        _.forEach(value.parameters, function (val, index) {
                            if (val.frontendType != 'INPUT' && val.frontendType != 'TEXTAREA') {
                                value.parameters[index].optionsUrl = './api/plugin/publish/list_values/' + value.target + '/' + val.id;
                                value.parameters[index].optionOnSelect = function (id, $item) {
                                    var linkedParameters = $linq(value.parameters).where("x => x.linked == '" + id + "'").toArray();

                                    _.forEach(linkedParameters, function (lp) {
                                        var indexParameter = $linq(value.parameters).indexOf("x => x.id == '" + lp.id + "'");

                                        if (indexParameter != -1) {
                                            if (!vm.model[value.target]) {
                                                vm.model[value.target] = {};
                                            }
                                            vm.model[value.target][lp.id] = undefined;
                                            value.parameters[indexParameter].optionsUrl = './api/plugin/publish/list_values/' + value.target + '/' + lp.id + '/' + (($item && $item.id)?$item.id:$item);
                                        }
                                    })
                                }
                            } else {
                                value.parameters[index].optionsUrl = undefined;
                                if (!vm.model[value.target]) {
                                    vm.model[value.target] = {};
                                }
                                vm.model[value.target][val.id] = undefined;
                            }
                            value.parameters[index].showFunction = function(){
                                if(val.modules && val.modules.length > 1){
                                    var param = $linq(value.parameters).where("x => x.id == '" + val.modules[0] + "'").toArray();
                                    if(param && param.length > 0){
                                        var trobat = false;
                                        for(var i=1; i<val.modules.length; i++) {
                                            if (vm.model && vm.model[value.target] && vm.model[value.target][param[0].id] && vm.model[value.target][param[0].id] == val.modules[i]) {
                                                trobat = true;
                                            }
                                        }
                                        return trobat;
                                    }
                                    return true;
                                }else{
                                    return true;
                                }
                            }
                        })
                    }
                    vm.publishPlugins.push(value);
                })
            }).catch(function (error) {
                vm.publishPlugins = [];
            });

            vm.selectPlugin = function($event, index) {
                var checkbox = $event.target;

                vm.publishPlugins[index].selected = checkbox.checked;
            }

            $scope.$on('annexaCustomFieldComponentModelChanged', function (event, args) {
                if(args.plugin){
                    if(!vm.model[args.plugin]){
                        vm.model[args.plugin] = {};
                    }
                    vm.model[args.plugin][args.key] = args.model;
                }

            });

            var isPluginModelValid = function(target) {

            }
            this.$onInit = function () {
                vm.submitFunction = function () {
                    var self = this;
                    var havePlugins = false;
                    _.forEach(vm.publishPlugins, function (plugin) {
                        if(plugin.selected) {
                            havePlugins = true;
                            var a = vm;
                            var publishDocuments = [];
                            var parameters = [];
                            _.forEach(plugin.parameters, function (value) {
                                var parameter = {id:value.id, value:vm.model[plugin.target][value.id]};
                                if(parameter.value && parameter.value.id){
                                    parameter.value = parameter.value.id;
                                }
                                parameters.push(parameter);
                            });

                            _.forEach(vm.documents, function(document){
                                var publishDocument = {
                                    document: {id:document.id},
                                    target: plugin.target,
                                    published: false
                                };
                                if(vm.dossierId) {
                                    publishDocument.originType = 'DOSSIER';
                                    publishDocument.originId = vm.dossierId;
                                } else {
                                    publishDocument.originType = 'NONE';
                                }
                                publishDocument.parameter = angular.toJson(parameters);
                                publishDocuments.push(publishDocument);
                            });
                            RestService.insert('./api/plugin/publish/documents', publishDocuments).then(function (data) {
                                self.close();
                                $rootScope.$broadcast('documentActionButtonExecuted', {button:'publishDocument', data:data.document});
                            }).catch(function () {
                                self.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
                            })
                        }
                    });
                    if(!havePlugins){
                        self.close();
                    }
                }
            }
        }],
        bindings: {
            documents: '=',
            dossierId: '@?',
            submitFunction: '='
        }
    })