/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentActions', {
        templateUrl: './components/doc/annexa-document-actions/annexa-document-actions.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller:['$rootScope', 'AnnexaPermissionsFactory', '$state','AnnexaDocumentActionsButtonFactory', 'GlobalDataFactory', function ($rootScope, AnnexaPermissionsFactory, $state, AnnexaDocumentActionsButtonFactory, GlobalDataFactory) {
            var vm = this;

            vm.signModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sign'").toArray();

            vm.showPrintLabel = false;
            if($rootScope.app.configuration && $rootScope.app.configuration.document_show_print_label && $rootScope.app.configuration.document_show_print_label.value && vm.documentComponent && vm.documentComponent.document && AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(vm.documentComponent.document, false)){
                vm.showPrintLabel =  $rootScope.app.configuration.document_show_print_label.value
            }

            vm.showButton = function(button) {
                switch (button) {
                    case 'SendToSign':
                        if(!_.contains(['NEW_AND_ATTACHMENT', 'COMPLETE', 'SIGNED'], vm.documentComponent.document.docStatus)  ||  !AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(vm.documentComponent.document, true) || vm.signModule.length < 1) {
                            return false;
                        }

                        return true;
                        break;
                    case 'PrintProtected':
                    	if(vm.documentComponent.document.docStatus != 'NEW' || (vm.documentComponent.document.signProcesses && vm.documentComponent.document.signProcesses.length > 0)) {
                            if(AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(vm.documentComponent.document, true)){
                                return false;
                            }else{
                                return true;
                            }
                        }

                        return true;
                        break;
                    case 'Print':
                        if(vm.documentComponent.document.docStatus != 'NEW' || (vm.documentComponent.document.signProcesses && vm.documentComponent.document.signProcesses.length > 0)) {
                            if(AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(vm.documentComponent.document, false)){
                                return false;
                            }else{
                                return true;
                            }
                        }

                        return true;
                        break;
                }
            }


        }]
    })