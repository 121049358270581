/**
 * Created by osirvent on 26/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentSignatureCircuit', {
        templateUrl: './components/doc/annexa-document-signature-circuit/annexa-document-signature-circuit.html',
        controller: ['Language', function (Language) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

            var signStates = [
                { id: 'PENDING', code: 'P', style: '' },
                { id: 'REJECTED', code: 'R', style: 'warning' },
                { id: 'FINALLY', code: 'F', style: 'success' },
                { id: 'IDLE', code: 'E', style: ''}
            ]

            vm.getSignStateStyle = function(state) {
                var stateObj = $linq(signStates).singleOrDefault(undefined, "x => x.id == '" + state + "'");
                return stateObj ? stateObj.style : '';
            }

            vm.getUserName = function(user) {
                return user.name + ' ' + user.surename1 + (user.surename2 ? ' ' + user.surename2 : '');
            }
        }],
        bindings: {
            document: '='
        }
    })